import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { change } from 'redux-form';
import moment from 'moment';
import {
  INITIAL_ORDER_BY_PROFILES,
  MENUID_ATTENDANCE_ADDON_SUMMARY,
  RXFIELD_SUMMARY_MONTH,
  RXFIELD_SUMMARY_USER,
  RXFIELD_SUMMARY_YEAR,
  RXFORM_SUMMARY,
} from '../../constant';
import {
  debounceSearch, sortAsc, transformUserDropdownData, transformInitialValues,
} from '../../helper';
import {
  clearProfiles, setActiveSideMenuItem, setAlertErrorMessage,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setProfileSelectedPageSize, setProfileSelectedOrderBy,
  downloadAttendanceSummaryAsync,
  clearAttendanceAddonSummary,
  setAttendanceAddonSummaryTappedId,
  exportAttendanceAddonSummaryAsync,
  setAttendanceAddonSummarySelectedMonth,
  setAttendanceAddonSummarySelectedYear,
} from '../../redux/action';
import { downloadProfilesAsync } from '../../../../redux/action';
import SummaryPage from './summary.presentation';

const getInitialValues = (state) => {
  const {
    attendanceAddonSummary, uiAttendanceAddonSummary,
  } = state;
  const { downloading } = uiAttendanceAddonSummary;

  const initVal = !isEmpty(attendanceAddonSummary) && !downloading
    ? transformInitialValues(attendanceAddonSummary)
    : {};

  return initVal;
};

const getYears = () => {
  const currentYear = moment().year();
  const maxYears = 5;
  const pastYears = [];
  for (let i = maxYears; i > 0; i -= 1) {
    pastYears.push(currentYear - i);
  }
  const years = [
    ...pastYears.sort((a, b) => sortAsc(a, b)),
    currentYear,
  ];
  return years;
};

const mapStateToProps = (state) => ({
  downloadingSummary: state.uiAttendanceAddonSummary.downloading,
  downloadingProfiles: state.uiProfile.downloading,
  profiles: transformUserDropdownData(state.profiles.data),
  tappedId: state.uiAttendanceAddonSummary.tappedId,
  profileSearchText: state.uiProfile.searchBarText,
  initialValues: getInitialValues(state),
  exporting: state.uiAttendanceAddonSummary.exporting,
  selectedMonth: state.uiAttendanceAddonSummary.selectedMonth,
  selectedYear: state.uiAttendanceAddonSummary.selectedYear,
  months: moment.months(),
  years: getYears(),
});

const searchUserDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_SUMMARY));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString('status=enabled'));
    dispatch(setProfileSearchText(''));
    dispatch(clearProfiles());
    dispatch(setProfileSelectedPageSize(20));
    dispatch(setProfileSelectedOrderBy(INITIAL_ORDER_BY_PROFILES));
    dispatch(downloadProfilesAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onChangeUserText: (text) => {
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch, true);
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setAttendanceAddonSummaryTappedId(option.value));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_USER, option,
      ));
    } else {
      dispatch(setAttendanceAddonSummaryTappedId(''));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_USER, '',
      ));
    }
  },
  onExportToPDFPressed: () => {
    dispatch(exportAttendanceAddonSummaryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
  onMonthOptionSelected: (option) => {
    if (option) {
      dispatch(setAttendanceAddonSummarySelectedMonth(option));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_MONTH, option,
      ));
    } else {
      dispatch(setAttendanceAddonSummarySelectedMonth(''));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_MONTH, '',
      ));
    }
  },
  onYearOptionSelected: (option) => {
    if (option) {
      dispatch(setAttendanceAddonSummarySelectedYear(option));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_YEAR, option,
      ));
    } else {
      dispatch(setAttendanceAddonSummarySelectedYear(''));
      dispatch(change(
        RXFORM_SUMMARY, RXFIELD_SUMMARY_YEAR, '',
      ));
    }
  },
  onAllFieldSelected: () => {
    dispatch(clearAttendanceAddonSummary());
    dispatch(downloadAttendanceSummaryAsync())
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryPage);
