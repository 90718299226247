import {
    SET_ATTENDANCE_ADDON_SUMMARY, CLEAR_ATTENDANCE_ADDON_SUMMARY,
  } from '../action';
  
  export default (state = {}, action) => {
    switch (action.type) {
      case SET_ATTENDANCE_ADDON_SUMMARY: {
        return action.data;
      }
      case CLEAR_ATTENDANCE_ADDON_SUMMARY:
        return state;
      default:
        return state;
    }
  };
  