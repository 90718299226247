import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    workingHourPage: {
      title: 'Working Hour',

      labelNo: 'No',
      labelName: 'Name',
      labelStartWorkingHour: 'Start Working Hour',
      labelEndWorkingHour: 'End Working Hour',
      labelTimeZone: 'Time Zone',
      labelCreatedDate: 'Created Date',

      placeholderName: 'Name',
      placeholderDescription: 'Description',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderWorkingHourTimeZone: 'Working Hour TimeZone',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',

      buttonCaptionEnable: 'Enable',
      buttonCaptionDisable: 'Disable',
      buttonCaptionCreateNewWorkingHour: 'Create New Working Hour',
      buttonCaptionDeleteWorkingHour: 'Delete Working Hour',
      buttonCaptionEditWorkingHour: 'Edit Working Hour',

      msgDisableConfirmation: 'Are you sure want to disable this item?',
      msgEnableConfirmation: 'Are you sure want to enable this item?',
    },
    wfaRequestPage: {
      title: 'WFA Request',

      labelNo: 'No',
      labelApprovalID: 'Approval ID',
      labelApprovalNumber: 'Approval Number',
      labelApprovalPic: 'Approval PIC',
      labelApprovalPicID: 'Approval PIC ID',
      labelApprovalPicName: 'Approval PIC Name',
      labelPic: 'Approval PIC',
      labelRequester: 'Requester',
      labelRequesterID: 'Requester ID',
      labelRequesterName: 'Requester Name',
      labelApprovalStatus: 'Approval Status',
      labelCreatedDate: 'Created Date',
      labelDates: 'Dates',
    },
    workingHourAdjustmentPage: {
      title: 'Working Hour Adjustment',

      labelNo: 'No',
      labelApprovalNumber: 'Approval Number',
      labelRequester: 'Requester',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelWorkingHour: 'Working Hour',
      labelApprovalStatus: 'Approval Status',
      labelCreatedDate: 'Created Date',
      labelPIC: 'Approval PIC',

      placeholderStartDate: 'Start Date',
      placeholderEndDate: 'End Date',
      placeholderApprovalStatus: 'Approval Status',
      placeholderApprovalNumber: 'Approval Number',
      placeholderApprovalId: 'Approval ID',
      placeholderRequesterId: 'Requester ID',
      placeholderRequesterName: 'Requester Name',
      placeholderWorkingHourId: 'Working Hour ID',
      placeholderWorkingHourName: 'Working Hour Name',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderWorkingHourTimeZone: 'Working Hour TimeZone',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',
      placeholderApprovalStatusTime: 'Approval Status Time',
      placeholderApprovalPicId: 'Approval PIC ID',
      placeholderApprovalPicName: 'Approval PIC Name',
      placeholderRequesterEmployeeId: 'Requester Employee ID',
      placeholderRequesterDepartment: 'Requester Department',
      placeholderRequesterDivision: 'Requester Division',
    },
    wfaQuotaPage: {
      title: 'WFA Quota',

      labelNo: 'No',
      labelDepartment: 'Department',
      labelQuota: 'Quota',
      labelCreatedDate: 'Created Date',

      placeholderDepartment: 'Department',
      placeholderQuotaPerMonth: 'Quota Per Month',

      buttonCaptionCreateNewWFAQuota: 'Create New WFA Quota',
      buttonCaptionEditWFAQuota: 'Edit WFA Quota',
      buttonCaptionDeleteWFAQuota: 'Delete WFA Quota',
    },
    wfaPage: {
      title: 'WFA',

      labelNo: 'No',
      labelRequestID: 'Request ID',
      labelRequester: 'Requester',
      labelRequesterID: 'Requester ID',
      labelRequesterName: 'Requester Name',
      labelDate: 'Date',
      labelWfaStatus: 'WFA Status',
      labelWfaStatusNotes: 'WFA Status Notes',
      labelCreatedDate: 'Created Date',
      labelRequesterEmployeeID: 'Requester Employee ID',
      labelRequesterDivision: 'Requester Division',
      labelRequesterDepartement: 'Requester Department',
      labelEditWfa: 'Edit WFA',

      placeholderNotes: 'Notes',

      buttonCaptionCancel: 'Cancel WFA',
      buttonCaptionEdit: 'Edit WFA',
    },
    attendanceCorrectionPage: {
      title: 'Attendance Correction',

      labelNo: 'No',
      labelApprovalNumber: 'Approval Number',
      labelRequester: 'Requester',
      labelClockIn: 'Clock In',
      labelClockOut: 'Clock Out',
      labelPic: 'PIC',
      labelApprovalStatus: 'Approval Status',
      labelAttendance: 'Attendance',
      labelCreatedDate: 'Created Date',
      labelWorkingHour: 'Custom Working Hour',

      placeholderApprovalId: 'Approval ID',
      placeholderApprovalNumber: 'Approval Number',
      placeholderApprovalStatus: 'Approval Status',
      placeholderApprovalStatusTime: 'Approval Status Time',
      placeholderApprovalPicId: 'Approval PIC ID',
      placeholderApprovalPicName: 'Approval PIC Name',
      placeholderClockIn: 'Clock In',
      placeholderClockOut: 'Clock Out',
      placeholderRequesterId: 'Requester ID',
      placeholderRequesterName: 'Requester Name',
      placeholderRequesterEmployeeId: 'Requester Employee ID',
      placeholderRequesterDivision: 'Requester Division',
      placeholderRequesterDepartment: 'Requester Department',
      placeholderClockInTime: 'Clock In Time',
      placeholderClockInStatus: 'Clock In Status',
      placeholderLocationId: 'Location ID',
      placeholderLocationName: 'Location Name',
      placeholderZone: 'Zone',
      placeholderNotes: 'Notes',
      placeholderLatitutde: 'Latitude',
      placeholderLongitude: 'Longitude',
      placeholderMap: 'Map',
      placeholderClockOutTime: 'Clock Out Time',
      placeholderClockOutStatus: 'Clock Out Status',
      placeholderStartWorkingHour: 'Start Working Hour',
      placeholderEndWorkingHour: 'End Working Hour',
      placeholderStartBeforeMin: 'Start Margin Before Minutes',
      placeholderStartAfterMin: 'Start Margin After Minutes',
      placeholderEndBeforeMin: 'End Margin Before Minutes',
      placeholderEndAfterMin: 'End Margin After Minutes',
      placeholderWorkingHourTimeZone: 'Working Hour TimeZone',
      placeholderEarliestClockIn: 'Earliest Clock In',
      placeholderAutoClockOut: 'Auto Clock Out',

      buttonCaptionDownloadAttachment: 'Download Attachment',
    },
    holidayPage: {
      title: 'Holiday',

      labelNo: 'No',
      labelDay: 'Day',
      labelMonth: 'Month',
      labelYear: 'Year',
      labelImportStatus: 'Import Status',
      labelFile: 'File',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',

      placeholderDay: 'Day',
      placeholderMonth: 'Month',
      placeholderYear: 'Year',
      placeholderImportStatus: 'Import Status',
      placeholderImportStatusNote: 'Import Status Notes',
      placeholderDescription: 'Description',

      buttonCaptionCreateNewHoliday: 'Create New Holiday',
    },
    personalHolidayPage: {
      title: 'Personal Holiday',

      labelNo: 'No',
      labelUser: 'User',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelImportStatus: 'Import Status',
      labelCreatedDate: 'Created Date',
      labelDescription: 'Description',

      placeholderUserId: 'User ID',
      placeholderUserName: 'User Full Name',
      placeholderEmployeeId: 'User Employee ID',
      placeholderDepartment: 'User Department',
      placeholderDivision: 'User Division',
      placeholderStartDate: 'Start Date',
      placeholderEndDate: 'End Date',
      placeholderImportStatus: 'Import Status',
      placeholderImportStatusNotes: 'Import Status Notes',
      placeholderDescription: 'Description',
      placeholderFile: 'File',

      buttonCaptionCreateNewPersonalHoliday: 'Create New Personal Holiday',
    },
    summaryPage: {
      title: 'Summary',

      labelSelectUser: 'Select User',
      labelMonths: 'Month',
      labelYears: 'Year',
      labelSelectMonth: 'Select Month',
      labelSelectYear: 'Select Year',
      labelHours: 'Hour(s)',
      labelDays: 'Day(s)',

      placeholderFullName: 'Full Name',
      placeholderPlannedTotalWorkingHour: 'Planned Total Working Hour',
      placeholderActualTotalWorkingHour: 'Actual Total Working Hour',
      placeholderLeave: 'Leave',
      placeholderWFA: 'WFA',

      buttonCaptionExportToPDF: 'Export to PDF',
    },
  },
  id: {
    workingHourPage: {
      title: 'Jam Kerja',

      labelNo: 'No',
      labelName: 'Nama',
      labelStartWorkingHour: 'Jam Mulai Kerja',
      labelEndWorkingHour: 'Jam Selesai Kerja',
      labelTimeZone: 'Zona Waktu',
      labelCreatedDate: 'Tanggal Dibuat',

      placeholderName: 'Nama',
      placeholderDescription: 'Deskripsi',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',

      buttonCaptionEnable: 'Aktifkan',
      buttonCaptionDisable: 'Nonaktifkan',
      buttonCaptionCreateNewWorkingHour: 'Buat Jam Kerja Baru',
      buttonCaptionDeleteWorkingHour: 'Hapus Jam Kerja',
      buttonCaptionEditWorkingHour: 'Ubah Jam Kerja',

      msgDisableConfirmation: 'Apa Anda yakin ingin menonaktifkan pilihan ini?',
      msgEnableConfirmation: 'Apa Anda yakin ingin mengaktifkan pilihan ini?',
    },
    wfaRequestPage: {
      title: 'Permintaan WFA',

      labelNo: 'No',
      labelApprovalID: 'ID Persetujuan',
      labelApprovalNumber: 'Nomor Persetujuan',
      labelApprovalPic: 'PIC Persetujuan',
      labelApprovalPicID: 'ID PIC Persetujuan',
      labelApprovalPicName: 'Nama PIC Persetujuan',
      labelPic: 'PIC Persetujuan',
      labelRequester: 'Pemohon',
      labelRequesterID: 'ID Pemohon',
      labelRequesterName: 'Nama Pemohon',
      labelApprovalStatus: 'Status Persetujuan',
      labelCreatedDate: 'Tanggal Dibuat',
      labelDates: 'Tanggal',
    },
    workingHourAdjustmentPage: {
      title: 'Penjadwalan Jam Kerja',

      labelNo: 'No',
      labelApprovalNumber: 'Nomor Persetujuan',
      labelRequester: 'Pemohon',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelWorkingHour: 'Jam Kerja',
      labelApprovalStatus: 'Status Persetujuan',
      labelCreatedDate: 'Tanggal Dibuat',
      labelPIC: 'PIC Persetujuan',

      placeholderStartDate: 'Tanggal Mulai',
      placeholderEndDate: 'Tanggal Selesai',
      placeholderApprovalStatus: 'Status Persetujuan',
      placeholderApprovalNumber: 'Nomor Persetujuan',
      placeholderApprovalId: 'ID Persetujuan',
      placeholderRequesterId: 'ID Pemohon',
      placeholderRequesterName: 'Nama Pemohon',
      placeholderWorkingHourId: 'ID Jam Kerja',
      placeholderWorkingHourName: 'Nama Jam Kerja',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',
      placeholderApprovalStatusTime: 'Waktu Status Persetujuan',
      placeholderApprovalPicId: 'ID PIC Persetujuan',
      placeholderApprovalPicName: 'Nama PIC Persetujuan',
      placeholderRequesterEmployeeId: 'ID Karyawan Pemohon',
      placeholderRequesterDepartment: 'Departemen Pemohon',
      placeholderRequesterDivision: 'Divisi Pemohon',
    },
    wfaQuotaPage: {
      title: 'Kuota WFA',

      labelNo: 'No',
      labelDepartment: 'Departemen',
      labelQuota: 'Kuota',
      labelCreatedDate: 'Tanggal Dibuat',

      placeholderDepartment: 'Departemen',
      placeholderQuotaPerMonth: 'Kuota Per Bulan',

      buttonCaptionCreateNewWFAQuota: 'Buat Kuota WFA',
      buttonCaptionEditWFAQuota: 'Ubah Kuota WFA',
      buttonCaptionDeleteWFAQuota: 'Hapus Kuota WFA',
    },
    wfaPage: {
      title: 'WFA',

      labelNo: 'No',
      labelRequestID: 'ID Pemohon',
      labelRequester: 'Pemohon',
      labelRequesterID: 'ID Pemohon',
      labelRequesterName: 'Nama Pemohon',
      labelDate: 'Tanggal',
      labelWfaStatus: 'Status WFA',
      labelWfaStatusNotes: 'Catatan Status WFA',
      labelCreatedDate: 'Tanggal Dibuat',
      labelRequesterEmployeeID: 'ID Karyawan Pemohon',
      labelRequesterDivision: 'Divisi Pemohon',
      labelRequesterDepartement: 'Departemen Pemohon',
      labelEditWfa: 'Ubah WFA',

      placeholderNotes: 'Catatan',

      buttonCaptionCancel: 'Batalkan WFA',
      buttonCaptionEdit: 'Ubah WFA',
    },
    attendanceCorrectionPage: {
      title: 'Revisi Kehadiran',

      labelNo: 'No',
      labelApprovalNumber: 'Nomor Persetujuan',
      labelRequester: 'Pemohon',
      labelClockIn: 'Absen Masuk',
      labelClockOut: 'Absen Keluar',
      labelPic: 'PIC',
      labelApprovalStatus: 'Status Persetujuan',
      labelAttendance: 'Kehadiran',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelWorkingHour: 'Penyesuaian Jam Kerja',

      placeholderApprovalId: 'ID Persetujuan',
      placeholderApprovalNumber: 'Nomor Persetujuan',
      placeholderApprovalStatus: 'Status Persetujuan',
      placeholderApprovalStatusTime: 'Waktu Status Persetujuan',
      placeholderApprovalPicId: 'ID PIC Persetujuan',
      placeholderApprovalPicName: 'Nama PIC Persetujuan',
      placeholderClockIn: 'Absen Masuk',
      placeholderClockOut: 'Absen Keluar',
      placeholderRequesterId: 'ID Pemohon',
      placeholderRequesterName: 'Nama Pemohon',
      placeholderRequesterEmployeeId: 'ID Karyawan Pemohon',
      placeholderRequesterDivision: 'Divisi Pemohon',
      placeholderRequesterDepartment: 'Departemen Pemohon',
      placeholderClockInTime: 'Waktu Masuk',
      placeholderClockInStatus: 'Status Masuk',
      placeholderLocationId: 'ID Lokasi',
      placeholderLocationName: 'N Lokasi',
      placeholderZone: 'Zona',
      placeholderNotes: 'Catatan',
      placeholderLatitutde: 'Garis Lintang',
      placeholderLongitude: 'Garis Bujur',
      placeholderMap: 'Peta',
      placeholderClockOutTime: 'Waktu Keluar',
      placeholderClockOutStatus: 'Status Keluar',
      placeholderStartWorkingHour: 'Jam Mulai Kerja',
      placeholderEndWorkingHour: 'Jam Selesai Kerja',
      placeholderStartBeforeMin: 'Margin Mulai Sebelum Menit',
      placeholderStartAfterMin: 'Margin Mulai Sesudah Menit',
      placeholderEndBeforeMin: 'Margin Selesai Sebelum Menit',
      placeholderEndAfterMin: 'Margin Selesai Sesudah Menit',
      placeholderWorkingHourTimeZone: 'Zona Waktu Jam Kerja',
      placeholderEarliestClockIn: 'Jam Mulai Kerja Paling Awal',
      placeholderAutoClockOut: 'Jam Selesai Kerja Otomatis',

      buttonCaptionDownloadAttachment: 'Unduh Lampiran',
    },
    holidayPage: {
      title: 'Hari Libur',

      labelNo: 'No',
      labelDay: 'Hari',
      labelMonth: 'Bulan',
      labelYear: 'Tahun',
      labelImportStatus: 'Impor Status',
      labelFile: 'File',
      labelCreatedDate: 'Tanggal Dibuat',
      labelDescription: 'Deskripsi',

      placeholderDay: 'Hari',
      placeholderMonth: 'Bulan',
      placeholderYear: 'Tahun',
      placeholderImportStatus: 'Impor Status',
      placeholderImportStatusNote: 'Catatan Impor Status',
      placeholderDescription: 'Deskripsi',

      buttonCaptionCreateNewHoliday: 'Buat Hari Libur Baru',
    },
    personalHolidayPage: {
      title: 'Hari Libur Personal',

      labelNo: 'No',
      labelUser: 'Pengguna',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Berakhir',
      labelImportStatus: 'Status Impor',
      labelCreatedDate: 'Tanggal Pembuatan',
      labelDescription: 'Deskripsi',

      placeholderUserId: 'ID Pengguna',
      placeholderUserName: 'Nama Lengkap Pengguna',
      placeholderEmployeeId: 'ID Karyawan Pengguna',
      placeholderDepartment: 'Departemen Pengguna',
      placeholderDivision: 'Divisi Pengguna',
      placeholderStartDate: 'Tanggal Mulai',
      placeholderEndDate: 'Tanggal Berakhir',
      placeholderImportStatus: 'Status Impor',
      placeholderImportStatusNotes: 'Catatan Status Impor',
      placeholderDescription: 'Deskripsi',
      placeholderFile: 'Berkas',

      buttonCaptionCreateNewPersonalHoliday: 'Buat Hari Libur Personal Baru',
    },
    summaryPage: {
      title: 'Rangkuman',

      labelSelectUser: 'Pilih Pengguna',
      labelMonths: 'Bulan',
      labelYears: 'Tahun',
      labelSelectMonth: 'Pilih Bulan',
      labelSelectYear: 'Pilih Tahun',
      labelHours: 'Jam',
      labelDays: 'Hari',

      placeholderFullName: 'Nama Lengkap',
      placeholderPlannedTotalWorkingHour: 'Total Jam Kerja Direncanakan',
      placeholderActualTotalWorkingHour: 'Total Jam Kerja Aktual',
      placeholderLeave: 'Cuti',
      placeholderWFA: 'WFA',

      buttonCaptionExportToPDF: 'Ekspor ke PDF',
    },
  },
});

export default LocalizedString;
