import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducer';
import { IS_PRODUCTION } from '../constant';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication', 'currentUser'],
  blacklist: [
    'form',
    'uiAchievement',
    'uiActivity',
    'uiActivityType',
    'uiAdditionalField',
    'uiAnnotationDesign',
    'uiAnnotationDesignList',
    'uiApiKey',
    'uiApplication',
    'uiApproval',
    'uiArticle',
    'uiArticleCategory',
    'uiAttendanceAddonSummary',
    'uiAttendanceCorrection',
    'uiAttendanceSummary',
    'uiAuditTrail',
    'uiAnalytics',
    'uiBanner',
    'uiBasePage',
    'uiBranch',
    'uiCatalog',
    'uiCalendar',
    'uiChallenge',
    'uiCity',
    'uiColorPickerField',
    'uiCompany',
    'uiCompanyMapping',
    'uiConfigAction',
    'uiConfigGroup',
    'uiConfigItem',
    'uiConfigValue',
    'uiCovidDashboard',
    'uiCovidData',
    'uiCustomer',
    'uiDealer',
    'uiDiscussion',
    'uiDiscussionComment',
    'uiDistrict',
    'uiDocument',
    'uiEmail',
    'uiEvent',
    'uiFAQ',
    'uiFAQCategory',
    'uiForumCategory',
    'uiForumPost',
    'uiFunctionalPage',
    'uiGameQRCategory',
    'uiHistory',
    'uiHoliday',
    'uiInbox',
    'uiIndustry',
    'uiInfoMapping',
    'uiJob',
    'uiLeaderBoard',
    'uiLocation',
    'uiLogin',
    'uiModel',
    'uiMyJob',
    'uiNews',
    'uiNewsCategory',
    'uiNewsDashboard',
    'uiNotificationTask',
    'uiPacket',
    'uiPacketSummary',
    'uiPacketType',
    'uiParentCalendars',
    'uiPartnerMerchant',
    'uiPdfList',
    'uiPermission',
    'uipersonalHoliday',
    'uiPickupLocation',
    'uiPoint',
    'uiPointSubmission',
    'uiPointTransaction',
    'uiProfile',
    'uiProfileSync',
    'uiPromotedMerchant',
    'uiProvince',
    'uiPurchaseRequest',
    'uiPushNotification',
    'uiQuiz',
    'uiQuizCategory',
    'uiQuizQuestion',
    'uiQuizResponse',
    'uiRegistration',
    'uiReportedComment',
    'uiRole',
    'uiScope',
    'uiService',
    'uiServiceRequest',
    'uiShortlink',
    'uiSms',
    'uiSubdistrict',
    'uiSummary',
    'uiSurvey',
    'uiSurveyCategory',
    'uiSurveyQuestion',
    'uiSurveyResponse',
    'uiTemplate',
    'uiToken',
    'uiTokenImport',
    'uiTokenRequest',
    'uiUploadPdf',
    'uiUser',
    'uiVoidPointRequest',
    'uiVoucher',
    'uiVoucherCategory',
    'uiVoucherPurchaseRequest',
    'uiVoucherToken',
    'uiWfa',
    'uiWFAQuota',
    'uiWfaRequest',
    'uiWhatsapp',
    'uiWorkingHour',
    'uiWorkingHourAdjustment',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = IS_PRODUCTION
  ? createStore(persistedReducer, applyMiddleware(thunk))
  : createStore(persistedReducer, applyMiddleware(thunk, logger));
export const persistor = persistStore(store);
