import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_WORKING_HOURS = 'DOWNLOADING_WORKING_HOURS';
export const downloadingWorkingHours = makeActionCreator(DOWNLOADING_WORKING_HOURS, 'status');

export const SET_WORKING_HOURS = 'SET_WORKING_HOURS';
export const setWorkingHours = makeActionCreator(SET_WORKING_HOURS, 'data');

export const ADD_WORKING_HOUR = 'ADD_WORKING_HOUR';
export const addWorkingHour = makeActionCreator(ADD_WORKING_HOUR, 'data');

export const CLEAR_WORKING_HOURS = 'CLEAR_WORKING_HOURS';
export const clearWorkingHours = makeActionCreator(CLEAR_WORKING_HOURS);

export const ADDING_EDITING_WORKING_HOUR = 'ADDING_EDITING_WORKING_HOUR';
export const addingEditingWorkingHour = makeActionCreator(ADDING_EDITING_WORKING_HOUR, 'status');

export const DOWNLOADING_DELETING_WORKING_HOUR = 'DOWNLOADING_DELETING_WORKING_HOUR';
export const downloadingDeletingWorkingHour = makeActionCreator(DOWNLOADING_DELETING_WORKING_HOUR, 'status');

export const ENABLING_DISABLING_WORKING_HOUR = 'ENABLING_DISABLING_WORKING_HOUR';
export const enablingDisablingWorkingHour = makeActionCreator(ENABLING_DISABLING_WORKING_HOUR, 'status');


export const SET_WORKING_HOUR_SELECTED_PAGE_SIZE = 'SET_WORKING_HOUR_SELECTED_PAGE_SIZE';
export const setWorkingHourSelectedPageSize = makeActionCreator(SET_WORKING_HOUR_SELECTED_PAGE_SIZE, 'size');

export const SET_WORKING_HOUR_SELECTED_ORDER_BY = 'SET_WORKING_HOUR_SELECTED_ORDER_BY';
export const setWorkingHourSelectedOrderBy = makeActionCreator(SET_WORKING_HOUR_SELECTED_ORDER_BY, 'order');

export const SET_WORKING_HOUR_TAPPED_ID = 'SET_WORKING_HOUR_TAPPED_ID';
export const setWorkingHourTappedId = makeActionCreator(SET_WORKING_HOUR_TAPPED_ID, 'id');

export const SET_WORKING_HOUR_SEARCH_TEXT = 'SET_WORKING_HOUR_SEARCH_TEXT';
export const setWorkingHourSearchText = makeActionCreator(SET_WORKING_HOUR_SEARCH_TEXT, 'text');

export const SET_WORKING_HOUR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_WORKING_HOUR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setWorkingHourAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_WORKING_HOUR_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_WORKING_HOUR_SELECTED_TIMEZONE = 'SET_WORKING_HOUR_SELECTED_TIMEZONE';
export const setWorkingHourSelectedTimezone = makeActionCreator(SET_WORKING_HOUR_SELECTED_TIMEZONE, 'value');


export const DOWNLOADING_WFA_REQUESTS = 'DOWNLOADING_WFA_REQUESTS';
export const downloadingWfaRequests = makeActionCreator(DOWNLOADING_WFA_REQUESTS, 'status');

export const SET_WFA_REQUESTS = 'SET_WFA_REQUESTS';
export const setWfaRequests = makeActionCreator(SET_WFA_REQUESTS, 'data');

export const ADD_WFA_REQUEST = 'ADD_WFA_REQUEST';
export const addWfaRequest = makeActionCreator(ADD_WFA_REQUEST, 'data');

export const CLEAR_WFA_REQUESTS = 'CLEAR_WFA_REQUESTS';
export const clearWfaRequests = makeActionCreator(CLEAR_WFA_REQUESTS);

export const DOWNLOADING_WFA_REQUEST = 'DOWNLOADING_WFA_REQUEST';
export const downloadingWfaRequest = makeActionCreator(DOWNLOADING_WFA_REQUEST, 'status');


export const SET_WFA_REQUEST_SELECTED_PAGE_SIZE = 'SET_WFA_REQUEST_SELECTED_PAGE_SIZE';
export const setWfaRequestSelectedPageSize = makeActionCreator(SET_WFA_REQUEST_SELECTED_PAGE_SIZE, 'size');

export const SET_WFA_REQUEST_SELECTED_ORDER_BY = 'SET_WFA_REQUEST_SELECTED_ORDER_BY';
export const setWfaRequestSelectedOrderBy = makeActionCreator(SET_WFA_REQUEST_SELECTED_ORDER_BY, 'order');

export const SET_WFA_REQUEST_TAPPED_ID = 'SET_WFA_REQUEST_TAPPED_ID';
export const setWfaRequestTappedId = makeActionCreator(SET_WFA_REQUEST_TAPPED_ID, 'id');

export const SET_WFA_REQUEST_SEARCH_TEXT = 'SET_WFA_REQUEST_SEARCH_TEXT';
export const setWfaRequestSearchText = makeActionCreator(SET_WFA_REQUEST_SEARCH_TEXT, 'text');

export const SET_WFA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_WFA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setWfaRequestAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_WFA_REQUEST_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_WORKING_HOUR_ADJUSTMENTS = 'DOWNLOADING_WORKING_HOUR_ADJUSTMENTS';
export const downloadingWorkingHourAdjustments = makeActionCreator(DOWNLOADING_WORKING_HOUR_ADJUSTMENTS, 'status');

export const SET_WORKING_HOUR_ADJUSTMENTS = 'SET_WORKING_HOUR_ADJUSTMENTS';
export const setWorkingHourAdjustments = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENTS, 'data');

export const ADD_WORKING_HOUR_ADJUSTMENT = 'ADD_WORKING_HOUR_ADJUSTMENT';
export const addWorkingHourAdjustment = makeActionCreator(ADD_WORKING_HOUR_ADJUSTMENT, 'data');

export const CLEAR_WORKING_HOUR_ADJUSTMENTS = 'CLEAR_WORKING_HOUR_ADJUSTMENTS';
export const clearWorkingHourAdjustments = makeActionCreator(CLEAR_WORKING_HOUR_ADJUSTMENTS);

export const DOWNLOADING_WORKING_HOUR_ADJUSTMENT = 'DOWNLOADING_WORKING_HOUR_ADJUSTMENT';
export const downloadingWorkingHourAdjustment = makeActionCreator(DOWNLOADING_WORKING_HOUR_ADJUSTMENT, 'status');


export const SET_WORKING_HOUR_ADJUSTMENT_SELECTED_PAGE_SIZE = 'SET_WORKING_HOUR_ADJUSTMENT_SELECTED_PAGE_SIZE';
export const setWorkingHourAdjustmentSelectedPageSize = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_SELECTED_PAGE_SIZE, 'size');

export const SET_WORKING_HOUR_ADJUSTMENT_SELECTED_ORDER_BY = 'SET_WORKING_HOUR_ADJUSTMENT_SELECTED_ORDER_BY';
export const setWorkingHourAdjustmentSelectedOrderBy = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_SELECTED_ORDER_BY, 'order');

export const SET_WORKING_HOUR_ADJUSTMENT_TAPPED_ID = 'SET_WORKING_HOUR_ADJUSTMENT_TAPPED_ID';
export const setWorkingHourAdjustmentTappedId = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_TAPPED_ID, 'id');

export const SET_WORKING_HOUR_ADJUSTMENT_SEARCH_TEXT = 'SET_WORKING_HOUR_ADJUSTMENT_SEARCH_TEXT';
export const setWorkingHourAdjustmentSearchText = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_SEARCH_TEXT, 'text');

export const SET_WORKING_HOUR_ADJUSTMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_WORKING_HOUR_ADJUSTMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setWorkingHourAdjustmentAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_WORKING_HOUR_ADJUSTMENT_SELECTED_REQUESTER = 'SET_WORKING_HOUR_ADJUSTMENT_SELECTED_REQUESTER';
export const setWorkingHourAdjustmentSelectedRequester = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_SELECTED_REQUESTER, 'value');

export const SET_WORKING_HOUR_ADJUSTMENT_SELECTED_WORKING_HOUR = 'SET_WORKING_HOUR_ADJUSTMENT_SELECTED_WORKING_HOUR';
export const setWorkingHourAdjustmentSelectedWorkingHour = makeActionCreator(SET_WORKING_HOUR_ADJUSTMENT_SELECTED_WORKING_HOUR, 'value');


export const DOWNLOADING_WFA_QUOTAS = 'DOWNLOADING_WFA_QUOTAS';
export const downloadingWFAQuotas = makeActionCreator(DOWNLOADING_WFA_QUOTAS, 'status');

export const SET_WFA_QUOTAS = 'SET_WFA_QUOTAS';
export const setWFAQuotas = makeActionCreator(SET_WFA_QUOTAS, 'data');

export const ADD_WFA_QUOTA = 'ADD_WFA_QUOTA';
export const addWFAQuota = makeActionCreator(ADD_WFA_QUOTA, 'data');

export const CLEAR_WFA_QUOTAS = 'CLEAR_WFA_QUOTAS';
export const clearWFAQuotas = makeActionCreator(CLEAR_WFA_QUOTAS);

export const ADDING_EDITING_WFA_QUOTA = 'ADDING_EDITING_WFA_QUOTA';
export const addingEditingWFAQuota = makeActionCreator(ADDING_EDITING_WFA_QUOTA, 'status');

export const DOWNLOADING_DELETING_WFA_QUOTA = 'DOWNLOADING_DELETING_WFA_QUOTA';
export const downloadingDeletingWFAQuota = makeActionCreator(DOWNLOADING_DELETING_WFA_QUOTA, 'status');


export const SET_WFA_QUOTA_SELECTED_PAGE_SIZE = 'SET_WFA_QUOTA_SELECTED_PAGE_SIZE';
export const setWFAQuotaSelectedPageSize = makeActionCreator(SET_WFA_QUOTA_SELECTED_PAGE_SIZE,
  'size');

export const SET_WFA_QUOTA_SELECTED_ORDER_BY = 'SET_WFA_QUOTA_SELECTED_ORDER_BY';
export const setWFAQuotaSelectedOrderBy = makeActionCreator(SET_WFA_QUOTA_SELECTED_ORDER_BY, 'order');

export const SET_WFA_QUOTA_TAPPED_ID = 'SET_WFA_QUOTA_TAPPED_ID';
export const setWFAQuotaTappedId = makeActionCreator(SET_WFA_QUOTA_TAPPED_ID, 'id');

export const SET_WFA_QUOTA_SEARCH_TEXT = 'SET_WFA_QUOTA_SEARCH_TEXT';
export const setWFAQuotaSearchText = makeActionCreator(SET_WFA_QUOTA_SEARCH_TEXT, 'text');

export const SET_WFA_QUOTA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_WFA_QUOTA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setWFAQuotaAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_WFA_QUOTA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_WFA_QUOTA_SELECTED_DEPARTMENT = 'SET_WFA_QUOTA_SELECTED_DEPARTMENT';
export const setWFAQuotaSelectedDepartment = makeActionCreator(SET_WFA_QUOTA_SELECTED_DEPARTMENT, 'value');


export const DOWNLOADING_WFAS = 'DOWNLOADING_WFAS';
export const downloadingWfas = makeActionCreator(DOWNLOADING_WFAS, 'status');

export const SET_WFAS = 'SET_WFAS';
export const setWfas = makeActionCreator(SET_WFAS, 'data');

export const ADD_WFA = 'ADD_WFA';
export const addWfa = makeActionCreator(ADD_WFA, 'data');

export const CLEAR_WFAS = 'CLEAR_WFAS';
export const clearWfas = makeActionCreator(CLEAR_WFAS);

export const ADDING_EDITING_WFA = 'ADDING_EDITING_WFA';
export const addingEditingWfa = makeActionCreator(ADDING_EDITING_WFA, 'status');

export const DOWNLOADING_WFA = 'DOWNLOADING_WFA';
export const downloadingWfa = makeActionCreator(DOWNLOADING_WFA, 'status');

export const CANCELING_WFA = 'CANCELING_WFA';
export const cancelingWfa = makeActionCreator(CANCELING_WFA, 'status');


export const SET_WFA_SELECTED_PAGE_SIZE = 'SET_WFA_SELECTED_PAGE_SIZE';
export const setWfaSelectedPageSize = makeActionCreator(SET_WFA_SELECTED_PAGE_SIZE, 'size');

export const SET_WFA_SELECTED_ORDER_BY = 'SET_WFA_SELECTED_ORDER_BY';
export const setWfaSelectedOrderBy = makeActionCreator(SET_WFA_SELECTED_ORDER_BY, 'order');

export const SET_WFA_TAPPED_ID = 'SET_WFA_TAPPED_ID';
export const setWfaTappedId = makeActionCreator(SET_WFA_TAPPED_ID, 'id');

export const SET_WFA_SEARCH_TEXT = 'SET_WFA_SEARCH_TEXT';
export const setWfaSearchText = makeActionCreator(SET_WFA_SEARCH_TEXT, 'text');

export const SET_WFA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_WFA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setWfaAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_WFA_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_WFA_EDIT_DIALOG_VISIBILITY = 'SET_WFA_EDIT_DIALOG_VISIBILITY';
export const setWfaEditDialogVisibility = makeActionCreator(SET_WFA_EDIT_DIALOG_VISIBILITY, 'status');


export const DOWNLOADING_ATTENDANCE_CORRECTIONS = 'DOWNLOADING_ATTENDANCE_CORRECTIONS';
export const downloadingAttendanceCorrections = makeActionCreator(DOWNLOADING_ATTENDANCE_CORRECTIONS, 'status');

export const SET_ATTENDANCE_CORRECTIONS = 'SET_ATTENDANCE_CORRECTIONS';
export const setAttendanceCorrections = makeActionCreator(SET_ATTENDANCE_CORRECTIONS, 'data');

export const ADD_ATTENDANCE_CORRECTION = 'ADD_ATTENDANCE_CORRECTION';
export const addAttendanceCorrection = makeActionCreator(ADD_ATTENDANCE_CORRECTION, 'data');

export const CLEAR_ATTENDANCE_CORRECTIONS = 'CLEAR_ATTENDANCE_CORRECTIONS';
export const clearAttendanceCorrections = makeActionCreator(CLEAR_ATTENDANCE_CORRECTIONS);

export const DOWNLOADING_ATTENDANCE_CORRECTION = 'DOWNLOADING_ATTENDANCE_CORRECTION';
export const downloadingAttendanceCorrection = makeActionCreator(DOWNLOADING_ATTENDANCE_CORRECTION, 'status');


export const SET_ATTENDANCE_CORRECTION_SELECTED_PAGE_SIZE = 'SET_ATTENDANCE_CORRECTION_SELECTED_PAGE_SIZE';
export const setAttendanceCorrectionSelectedPageSize = makeActionCreator(SET_ATTENDANCE_CORRECTION_SELECTED_PAGE_SIZE, 'size');

export const SET_ATTENDANCE_CORRECTION_SELECTED_ORDER_BY = 'SET_ATTENDANCE_CORRECTION_SELECTED_ORDER_BY';
export const setAttendanceCorrectionSelectedOrderBy = makeActionCreator(SET_ATTENDANCE_CORRECTION_SELECTED_ORDER_BY, 'order');

export const SET_ATTENDANCE_CORRECTION_TAPPED_ID = 'SET_ATTENDANCE_CORRECTION_TAPPED_ID';
export const setAttendanceCorrectionTappedId = makeActionCreator(SET_ATTENDANCE_CORRECTION_TAPPED_ID, 'id');

export const SET_ATTENDANCE_CORRECTION_SEARCH_TEXT = 'SET_ATTENDANCE_CORRECTION_SEARCH_TEXT';
export const setAttendanceCorrectionSearchText = makeActionCreator(SET_ATTENDANCE_CORRECTION_SEARCH_TEXT, 'text');

export const SET_ATTENDANCE_CORRECTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_ATTENDANCE_CORRECTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setAttendanceCorrectionAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_ATTENDANCE_CORRECTION_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');


export const DOWNLOADING_HOLIDAYS = 'DOWNLOADING_HOLIDAYS';
export const downloadingHolidays = makeActionCreator(DOWNLOADING_HOLIDAYS, 'status');

export const ADDING_HOLIDAYS = 'ADDING_HOLIDAYS';
export const addingHolidays = makeActionCreator(ADDING_HOLIDAYS, 'status');

export const SET_HOLIDAYS = 'SET_HOLIDAYS';
export const setHolidays = makeActionCreator(SET_HOLIDAYS, 'data');

export const ADD_HOLIDAY = 'ADD_HOLIDAY';
export const addHoliday = makeActionCreator(ADD_HOLIDAY, 'data');

export const CLEAR_HOLIDAYS = 'CLEAR_HOLIDAYS';
export const clearHolidays = makeActionCreator(CLEAR_HOLIDAYS);

export const DOWNLOADING_HOLIDAY = 'DOWNLOADING_HOLIDAY';
export const downloadingHoliday = makeActionCreator(DOWNLOADING_HOLIDAY, 'status');


export const SET_HOLIDAY_SELECTED_PAGE_SIZE = 'SET_HOLIDAY_SELECTED_PAGE_SIZE';
export const setHolidaySelectedPageSize = makeActionCreator(SET_HOLIDAY_SELECTED_PAGE_SIZE, 'size');

export const SET_HOLIDAY_SELECTED_ORDER_BY = 'SET_HOLIDAY_SELECTED_ORDER_BY';
export const setHolidaySelectedOrderBy = makeActionCreator(SET_HOLIDAY_SELECTED_ORDER_BY, 'order');

export const SET_HOLIDAY_TAPPED_ID = 'SET_HOLIDAY_TAPPED_ID';
export const setHolidayTappedId = makeActionCreator(SET_HOLIDAY_TAPPED_ID, 'id');

export const SET_HOLIDAY_SEARCH_TEXT = 'SET_HOLIDAY_SEARCH_TEXT';
export const setHolidaySearchText = makeActionCreator(SET_HOLIDAY_SEARCH_TEXT, 'text');

export const SET_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setHolidayAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_HOLIDAY_SELECTED_FILE = 'SET_HOLIDAY_SELECTED_FILE';
export const setHolidaySelectedFile = makeActionCreator(SET_HOLIDAY_SELECTED_FILE, 'text');

export const SET_HOLIDAY_SELECTED_YEAR = 'SET_HOLIDAY_SELECTED_YEAR';
export const setHolidaySelectedYear = makeActionCreator(SET_HOLIDAY_SELECTED_YEAR, 'text');


export const DOWNLOADING_PERSONAL_HOLIDAYS = 'DOWNLOADING_PERSONAL_HOLIDAYS';
export const downloadingPersonalHolidays = makeActionCreator(DOWNLOADING_PERSONAL_HOLIDAYS, 'status');

export const SET_PERSONAL_HOLIDAYS = 'SET_PERSONAL_HOLIDAYS';
export const setPersonalHolidays = makeActionCreator(SET_PERSONAL_HOLIDAYS, 'data');

export const ADD_PERSONAL_HOLIDAY = 'ADD_PERSONAL_HOLIDAY';
export const addPersonalHoliday = makeActionCreator(ADD_PERSONAL_HOLIDAY, 'data');

export const CLEAR_PERSONAL_HOLIDAYS = 'CLEAR_PERSONAL_HOLIDAYS';
export const clearPersonalHolidays = makeActionCreator(CLEAR_PERSONAL_HOLIDAYS);

export const ADDING_EDITING_PERSONAL_HOLIDAY = 'ADDING_EDITING_PERSONAL_HOLIDAY';
export const addingEditingPersonalHoliday = makeActionCreator(ADDING_EDITING_PERSONAL_HOLIDAY, 'status');

export const DOWNLOADING_PERSONAL_HOLIDAY = 'DOWNLOADING_PERSONAL_HOLIDAY';
export const downloadingPersonalHoliday = makeActionCreator(DOWNLOADING_PERSONAL_HOLIDAY, 'status');


export const SET_PERSONAL_HOLIDAY_SELECTED_PAGE_SIZE = 'SET_PERSONAL_HOLIDAY_SELECTED_PAGE_SIZE';
export const setPersonalHolidaySelectedPageSize = makeActionCreator(SET_PERSONAL_HOLIDAY_SELECTED_PAGE_SIZE, 'size');

export const SET_PERSONAL_HOLIDAY_SELECTED_ORDER_BY = 'SET_PERSONAL_HOLIDAY_SELECTED_ORDER_BY';
export const setPersonalHolidaySelectedOrderBy = makeActionCreator(SET_PERSONAL_HOLIDAY_SELECTED_ORDER_BY, 'order');

export const SET_PERSONAL_HOLIDAY_TAPPED_ID = 'SET_PERSONAL_HOLIDAY_TAPPED_ID';
export const setPersonalHolidayTappedId = makeActionCreator(SET_PERSONAL_HOLIDAY_TAPPED_ID, 'id');

export const SET_PERSONAL_HOLIDAY_SEARCH_TEXT = 'SET_PERSONAL_HOLIDAY_SEARCH_TEXT';
export const setPersonalHolidaySearchText = makeActionCreator(SET_PERSONAL_HOLIDAY_SEARCH_TEXT, 'text');

export const SET_PERSONAL_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING = 'SET_PERSONAL_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING';
export const setPersonalHolidayAdvancedFilterDialogSelectedFilterString = makeActionCreator(SET_PERSONAL_HOLIDAY_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING, 'text');

export const SET_PERSONAL_HOLIDAY_SELECTED_FILE = 'SET_PERSONAL_HOLIDAY_SELECTED_FILE';
export const setPersonalHolidaySelectedFile = makeActionCreator(SET_PERSONAL_HOLIDAY_SELECTED_FILE, 'base64');


export const DOWNLOADING_ATTENDANCE_ADDON_SUMMARY = 'DOWNLOADING_ATTENDANCE_ADDON_SUMMARY';
export const downloadingAttendanceAddonSummary = makeActionCreator(DOWNLOADING_ATTENDANCE_ADDON_SUMMARY, 'status');

export const SET_ATTENDANCE_ADDON_SUMMARY = 'SET_ATTENDANCE_ADDON_SUMMARY';
export const setAttendanceAddonSummary = makeActionCreator(SET_ATTENDANCE_ADDON_SUMMARY, 'data');

export const CLEAR_ATTENDANCE_ADDON_SUMMARY = 'CLEAR_ATTENDANCE_ADDON_SUMMARY';
export const clearAttendanceAddonSummary = makeActionCreator(CLEAR_ATTENDANCE_ADDON_SUMMARY);

export const SET_ATTENDANCE_ADDON_SUMMARY_TAPPED_ID = 'SET_ATTENDANCE_ADDON_SUMMARY_TAPPED_ID';
export const setAttendanceAddonSummaryTappedId = makeActionCreator(SET_ATTENDANCE_ADDON_SUMMARY_TAPPED_ID, 'id');

export const EXPORTING_ATTENDANCE_ADDON_SUMMARY = 'EXPORTING_ATTENDANCE_ADDON_SUMMARY';
export const exportingAttendanceAddonSummary = makeActionCreator(EXPORTING_ATTENDANCE_ADDON_SUMMARY, 'status');

export const SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_MONTH = 'SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_MONTH';
export const setAttendanceAddonSummarySelectedMonth = makeActionCreator(SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_MONTH, 'value');

export const SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_YEAR = 'SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_YEAR';
export const setAttendanceAddonSummarySelectedYear = makeActionCreator(SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_YEAR, 'value');
