import { isEmpty } from 'lodash';
import { REST_URL_ANALYTICS_DATA } from './constant';
import { sendPostRequest } from '../../helper';

export * from '../../helper';

export const getProgresssBarChartPercentage = (
  count, total,
) => Math.round((count / total) * 100);

const transformAnalyticsDataResponse = (handledResponse, key) => (
  handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      [key]: row.dimensionValues[0].value,
      count: row.metricValues[0].value,
    })).filter((row) => row[key] !== '(not set)') : []).slice(0, 7);

const getAnalyticsData = async (propertyId, body, token) => {
  const url = REST_URL_ANALYTICS_DATA.replace(/\{propertyId\}/, propertyId);
  const response = await sendPostRequest(url, body, token);
  return response;
};

export const getAnalyticsCountriesByUsersData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);
  return transformAnalyticsDataResponse(response, 'country');
};

const transformAnalyticsDevicesByUserResponse = (handledResponse) => {
  const dataArray = handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      device: row.dimensionValues[0].value,
      count: row.metricValues[0].value,
    })).filter((row) => row.message !== '(not set)').slice(0, 7) : [];

  const data = {
    dataArray,
    totalDevice: !isEmpty(handledResponse?.totals[0])
      ? handledResponse?.totals[0].metricValues[0]?.value : 0,
  };

  return data;
};

export const getAnalyticsDevicesByUsersData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);

  return transformAnalyticsDevicesByUserResponse(response);
};

export const getAnalyticsEventNamesCountsData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);
  return transformAnalyticsDataResponse(response, 'event');
};

const transformUserEngagementByScreenNameDataResponse = (handledResponse) => {
  const firstValueTotal = handledResponse.totals.find((data) => data.dimensionValues[2].value === 'date_range_0');
  const secondValueTotal = handledResponse.totals
    .find((data) => (!isEmpty(data)
      ? data?.dimensionValues[2].value === 'date_range_1'
      : false
    ));

  const total = {
    firstDateRangeTotal: {
      engagementValue: Number(firstValueTotal?.metricValues[0]?.value || '0'),
      averageTime: Number(firstValueTotal?.metricValues[1]?.value || '0'),
    },
    secondDateRangeTotal: {
      engagementValue: Number(secondValueTotal?.metricValues[0]?.value || '0'),
      averageTime: Number(secondValueTotal?.metricValues[1]?.value) || '0',
    },
  };

  const dataArray = handledResponse.rows.map((data) => ({
    screenName: data.dimensionValues[1].value,
    dateRange: data.dimensionValues[2].value,
    engagementValue: data.metricValues[0].value,
    averageTime: data.metricValues[1].value,
  }));
  const groupedDataArray = dataArray.reduce((result, current) => {
    const temp = result;
    temp[current.screenName] = temp[current.screenName] || [];
    temp[current.screenName].push(current);
    return result;
  }, {});
  const result = Object.entries(groupedDataArray).map((data) => {
    const firstValue = data[1].find((d) => d.dateRange === 'date_range_0');
    const secondValue = data[1].find((d) => d.dateRange === 'date_range_1');
    return {
      screenName: data[0],
      firstDateRange: {
        engagementValue: Number(firstValue?.engagementValue || '0'),
        averageTime: Number(firstValue?.averageTime || '0'),
      },
      secondDateRange: {
        engagementValue: Number(secondValue?.engagementValue || '0'),
        averageTime: secondValue?.averageTime ? Number(secondValue.averageTime) : 0,
      },
    };
  });
  return { dataArray: result, total };
};

export const getAnalyticsUserEngagementByScreenName = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);
  return transformUserEngagementByScreenNameDataResponse(response);
};

const transformAnalyticsCustomErrorMessagesResponse = (handledResponse) => {
  const dataArray = handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      eventName: row.dimensionValues[0].value,
      message: row.dimensionValues[1].value,
      eventCount: row.metricValues[0].value,
      userCount: row.metricValues[1].value,
    })) : [];

  const data = {
    dataArray,
    totalEvent: !isEmpty(handledResponse?.totals[0])
      ? handledResponse?.totals[0].metricValues[0]?.value : 0,
    totalUser: !isEmpty(handledResponse?.totals[0])
      ? handledResponse?.totals[0].metricValues[1]?.value : 0,
  };

  return data;
};

export const getAnalyticsCustomErrorMessages = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);
  return transformAnalyticsCustomErrorMessagesResponse(response);
};

export const getAnalyticsTop5AppVersionsData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);
  return transformAnalyticsDataResponse(response, 'version');
};

const transformUsersByAppVersionChartDataResponse = (handledResponse, top5AppVersions) => {
  const chartData = handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      day: row.dimensionValues[1].value ? Number(row.dimensionValues[1].value).toString() : '',
      version: row.dimensionValues[0].value,
      count: row.metricValues[0].value,
    })).reduce((result, current) => {
      const temp = result;
      if (!temp[current.day]) {
        temp[current.day] = { day: current.day, [current.version]: 0 };
      }
      temp[current.day] = { ...temp[current.day], [current.version]: Number(current.count) };
      return result;
    }, {}) : [];
  const charDataArray = Object.values(chartData);

  charDataArray.forEach((day) => top5AppVersions.forEach((topApp) => {
    const temp = day;
    if (typeof day[topApp] === 'undefined') {
      temp[topApp] = 0;
    }
  }));
  return charDataArray;
};

export const getAnalyticsUsersByAppVersionChartData = async (propertyId, body, top5AppVersions,
  token) => {
  const response = await getAnalyticsData(propertyId, body, token);

  return transformUsersByAppVersionChartDataResponse(response, top5AppVersions);
};

const transformActiveUserChartDataResponse = (handledResponse) => {
  const chartData = handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      day: row.dimensionValues[1].value ? Number(row.dimensionValues[1].value).toString() : '',
      country: row.dimensionValues[0].value,
      count: row.metricValues[0].value,
    })).reduce((result, current) => {
      const temp = result;
      if (!temp[current.day]) {
        temp[current.day] = { day: current.day, total: 0 };
      }
      temp[current.day] = {
        ...temp[current.day],
        total: Number(current.count) + temp[current.day].total,
      };

      return result;
    }, {}) : [];

  const charDataArray = Object.values(chartData);

  const total = handledResponse && handledResponse?.totals?.length > 0
    ? handledResponse?.totals[0]?.metricValues[0]?.value : 0;

  const combinedArray = [charDataArray, { totalUser: total }];

  return combinedArray;
};

export const getAnalyticsActiveUserChartData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);

  return transformActiveUserChartDataResponse(response);
};

const transformPageTitleAndScreenNameDataResponse = (handledResponse) => {
  const chartData = handledResponse && handledResponse?.rows?.length > 0
    ? handledResponse?.rows?.map((row) => ({
      views: row.metricValues[1].value,
      activeUsers: row.metricValues[0].value,
      viewsPerActiveUser: row.metricValues[2].value,
      averageEngagementPerActiveUser: row.metricValues[3].value
        / row.metricValues[0].value,
      screenPageName: row.dimensionValues[1]?.value || '-',
    })) : [];

  const charDataArray = Object.values(chartData);

  return charDataArray;
};

export const getAnalyticsPageTitleAndScreenNamesData = async (propertyId, body, token) => {
  const response = await getAnalyticsData(propertyId, body, token);

  return transformPageTitleAndScreenNameDataResponse(response);
};
